export const { 
    env = '',
    apiBaseUrl = '',
    apiKey = '',
    recaptchaSiteKey = '',
    conektaPublicKey = '',
    PROMO = '',
    DISCOUNT = '',
    PRICE = '',
    openPayPublicKey = '',
    openPayMerchantId = '',
    paypalClientId = '',
    key = '',
    invoicingBaseUrl = ''
} = JSON.parse(process.env.NEXT_PUBLIC_CONFIG || '{}');